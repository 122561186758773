import { useAuthenticator } from "@aws-amplify/ui-vue";
import { NavigationGuardNext } from "vue-router";

/**
 * Check if use is authenticated before enter
 * @param _to
 * @param _from
 * @param next
 */
export function onAuthRequired(_to, _from, next: NavigationGuardNext) {
  const auth = useAuthenticator();
  if (auth.route == "authenticated") {
    next();
  } else {
    next("/play");
  }
}
