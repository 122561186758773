<template>
  <router-view />
</template>

<style>
html,
body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
}

#app{
  height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
p,
span {
  margin: 0;
  padding: 10px;
}

p{
  line-height: 1.625;
}

h1 {
  font-size: 48px;
}

a {
  text-decoration: none;
  color: black;
}

.title{
  text-align: left;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes upAndDown {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}

@keyframes upAndDown {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
</style>