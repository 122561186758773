import { createApp } from "vue";
import App from "./App.vue";
import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import router from "./router";
import {
  applyPolyfills,
  defineCustomElements,
} from "@sme.up/ketchup/dist/loader";

Amplify.configure(awsconfig);

/**
 * Ketchup import
 */
applyPolyfills().then(() => {
  defineCustomElements();
});

// create app
const app = createApp(App);

// configure app
app.config.compilerOptions.isCustomElement = (tag) => tag.startsWith("kup");

// mount app
app.use(router).mount("#app");
