import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { onAuthRequired } from "./onAuthRequired";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "index",
    component: () => import("@/pages/Index.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: () => import("@/pages/Home.vue"),
      },
      {
        path: "/play",
        name: "play",
        component: () => import("@/pages/Play.vue"),
      },
      {
        path: "/edit",
        name: "edit",
        component: () => import("@/pages/Edit.vue"),
        beforeEnter: onAuthRequired,
      },
      {
        path: "/execute",
        name: "execute",
        component: () => import("@/pages/Execution.vue"),
        beforeEnter: onAuthRequired,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
